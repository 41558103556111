import styled from 'styled-components'

const Divider = styled.hr`
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.cardBorder};
  width: 100%;
`

export default Divider
