import { Text } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const TextGradient = styled(Text)`
  background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0.48) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`
