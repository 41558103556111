import { useMemo } from 'react'
import { Box, HypeIcon, Table, Text } from '@pancakeswap/uikit'
import { FlexGap } from 'components/Layout/Flex'
import { TextGradient } from 'components/TextGardient'
import { accountEllipsis } from 'utils'
import styled from 'styled-components'
import { useGetLeaderboard, useGetStats } from 'state/nft/hooks'
import PaginationCustom from 'components/Pagingation'
import { formatNumber } from 'utils/formatBalance'
import { Badge, CardRank } from './styles'
import { TOTAL_SUPPLY_MINT } from './config'

const Leaderboard = () => {
  const { topRank, rankList, page, setPage } = useGetLeaderboard()
  const { totalMinted, isLoading } = useGetStats()

  const memoRankList = useMemo(() => {
    return rankList.docs?.filter((v, index) => (page === 1 ? index > 2 : index)) ?? []
  }, [page, rankList])

  return (
    <FlexGap
      justifyContent="center"
      p={[20, null, '80px 10%']}
      flexDirection="column"
      alignItems="center"
      backgroundColor="background"
    >
      <TextGradient as="h4" fontSize={[32, null, 40]} textAlign="center">
        HYPERBOLT LEADERBOARD
      </TextGradient>
      <FlexGap gap="20px" width="100%" flexWrap="wrap" mt={[3, null, 5]} mb={4}>
        {!isLoading &&
          topRank &&
          topRank.docs?.length &&
          topRank.docs.map((item, index) => (
            <TopRank
              rank={index + 1}
              address={item.walletAddress}
              totalMint={item.countMint}
              totalMinted={totalMinted}
            />
          ))}
      </FlexGap>
      <Box p={[null, null, '0 20%']} width="100%">
        <TableCustom>
          <thead>
            <Th>#</Th>
            <Th>User</Th>
            <Th>Hyperbolt</Th>
            <Th>HP Rewards</Th>
          </thead>
          <tbody>
            {rankList && rankList.docs?.length ? (
              memoRankList.map((item, index) => (
                <tr>
                  <Td>{page === 1 ? index + 4 : 10 * (page - 1) + (index + 1)}</Td>
                  <Td>{accountEllipsis(item.walletAddress, 4)}</Td>
                  <Td>{item.countMint}</Td>
                  <Td>
                    <FlexGap alignItems="center" justifyContent="center">
                      <HypeIcon height={30} width={30} mr={2} />
                      <Text color="primary" fontWeight={500} fontSize={[20, null, 24]}>
                        {formatNumber(TOTAL_SUPPLY_MINT * (item.countMint / totalMinted), 0)}
                      </Text>
                    </FlexGap>
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={4}>
                  <Text>No data !</Text>
                </Td>
              </tr>
            )}
          </tbody>
        </TableCustom>
        <FlexGap justifyContent="center" mt={3}>
          <PaginationCustom
            current={page}
            total={rankList.totalDocs}
            pageSize={10}
            onChange={({ page: val }) => setPage(val)}
          />
        </FlexGap>
      </Box>
    </FlexGap>
  )
}

const TopRank = (props) => {
  const { rank, address, totalMint, totalMinted } = props
  const rewards = useMemo(() => {
    return TOTAL_SUPPLY_MINT * (totalMint / totalMinted)
  }, [totalMint, totalMinted])
  return (
    <CardRank {...props}>
      <FlexGap justifyContent="flex-end" alignItems="flex-end" p={[4, null, 4]} minHeight={250}>
        <Text as="h6" color="primary" fontWeight={700} fontSize={[20, null, 32]}>
          {accountEllipsis(address, 4)}
        </Text>
      </FlexGap>
      <FlexGap
        backgroundColor="primary3D"
        padding={[3, null, '0 20px']}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Badge>
          No{' '}
          <Text as="span" color="currentColor" fontSize={[24, null, 32]} mx={2} fontWeight={700}>
            {rank}
          </Text>
        </Badge>
        <Text fontSize={[20, null, 24]}>{totalMint} Bolts</Text>
        <FlexGap alignItems="center">
          <HypeIcon height={30} width={30} mr={2} />
          <Text color="primary" fontWeight={500} fontSize={[20, null, 24]}>
            {formatNumber(rewards, 0)}
          </Text>
        </FlexGap>
      </FlexGap>
    </CardRank>
  )
}

const Th = styled.th`
  color: ${({ theme }) => theme.colors.textBody3};
`

const Td = styled.td`
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
`

const TableCustom = styled(Table)`
  & td,
  th {
    height: 60px;
    vertical-align: middle;
    padding: 10px;
    cursor: default;
  }

  & td {
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 20px;
    }
  }

  & tr:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.tertiary};
  }
`

export default Leaderboard
