import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  max-width: 100%;\n  width: 100%;\n\n  tbody tr:last-child {\n    ",
        " {\n      border-bottom: 0;\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space } from "styled-system";
import { Td } from "./Cell";
var Table = styled.table.withConfig({
    componentId: "sc-4fe367f7-0"
})(_templateObject(), Td, space);
export default Table;
