import { ArrowBackIcon, ArrowForwardIcon } from '@pancakeswap/uikit'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import styled from 'styled-components'
// import React from 'react'
// import './pagination.scss'

interface Props {
  total: number
  simple?: boolean
  current: number
  onChange: any
  pageSize: number
}

const PaginationCustom: React.FC<Props> = (props) => {
  const onChange = (page) => {
    props.onChange({ page })
  }

  return (
    <PaginationStyled
      showTitle={false}
      className="pagination-antd__custom"
      {...props}
      onChange={onChange}
      hideOnSinglePage
      showLessItems
      nextIcon={<ArrowForwardIcon height="100%" color="currentColor" />}
      prevIcon={<ArrowBackIcon height="100%" color="currentColor" />}
    />
  )
}

const PaginationStyled = styled(Pagination)`
  .rc-pagination-item {
    border: none;
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.input};
    font-size: 16px;
    a {
      color: ${({ theme }) => theme.colors.textSubtle};
      padding: 12px;
      height: 100%;
      width: 100%;
    }
  }

  .rc-pagination-item-active {
    background-color: ${({ theme }) => theme.colors.primary};
    a {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .rc-pagination-simple-pager input {
    background-color: ${({ theme }) => theme.colors.input};
  }

  .rc-pagination-next,
  .rc-pagination-prev {
    color: ${({ theme }) => theme.colors.textSubtle};
    background-color: ${({ theme }) => theme.colors.input};

    &.rc-pagination-disabled {
      color: ${({ theme }) => theme.colors.textDisabled};
    }
  }
`

export default PaginationCustom
