import React from 'react'
import { Box, Flex, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { FlexGap } from 'components/Layout/Flex'
import { TextGradient } from 'components/TextGardient'
import MintNFT from './MintNFT'
import Reward from './Reward'
import Stats from './Stats'
import Leaderboard from './Leaderboard'

const Nft: React.FC = () => {
  return (
    <>
      <LayoutNFT flexDirection="column" p={[20, null, '50px 20%']} gap="40px">
        <Flex justifyContent="center" alignItems="center" flexDirection="column">
          <TextGradient fontSize={['46px', , '120px']} as="h1" fontWeight={600} textTransform="uppercase">
            Hyperbolt
          </TextGradient>
          <Flex flexDirection="column" alignItems="center" my={20}>
            <Text fontSize={['14px', null, '20px']}>Bold and Brave, HYPERBOLT aye!</Text>
            <Text fontSize={['14px', null, '20px']} textAlign="center">
              4x Reward guaranteed: 100% Blast Point, Blast Gold, ETH & 5,000,000 HYPE
            </Text>
          </Flex>
        </Flex>
        <MintNFT />
      </LayoutNFT>
      <LayoutRewardStats>
        <LayoutSymbol justifyContent="center" flex={1}>
          <LayoutStar flexWrap="wrap" justifyContent="center" gap="20px" flex={1} p={[20, null, '180px 0']}>
            <Reward />
            <Stats />
          </LayoutStar>
        </LayoutSymbol>
      </LayoutRewardStats>
      <Leaderboard />
    </>
  )
}

const LayoutNFT = styled(FlexGap)`
  background-image: url('/images/nft/grid.webp');
  background-size: cover;
  background-repeat: no-repeat;
`

const LayoutRewardStats = styled(FlexGap)`
  background-image: url('/images/nft/star.webp');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center bottom;
`

const LayoutStar = styled(FlexGap)`
  background-image: none;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom right;
  ${({ theme }) => theme.mediaQueries.md} {
    background-image: url('/images/nft/plannet.webp');
  }
`

const LayoutSymbol = styled(FlexGap)`
  background-image: url('/images/nft/sym.webp');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom left;
`

export default Nft
