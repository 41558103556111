import { useCallback, useState } from 'react'
import { useTranslation } from '@pancakeswap/localization'
import { useToast } from '@pancakeswap/uikit'
import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers'
import { ToastDescriptionWithTx } from 'components/Toast'
import { logError, isUserRejected, isRevertContract } from 'utils/sentry'
import useActiveWeb3React from './useActiveWeb3React'

export type TxResponse = TransactionResponse | null

export type CatchTxErrorReturn = {
  fetchWithCatchTxError: (fn: () => Promise<TxResponse>) => Promise<TransactionReceipt>
  loading: boolean
}

type ErrorData = {
  code: number
  message: string
}

type TxError = {
  data: ErrorData
  error: string
}

// -32000 is insufficient funds for gas * price + value
const isGasEstimationError = (err: TxError): boolean => err?.data?.code === -32000

export default function useCatchTxError(): CatchTxErrorReturn {
  const { provider } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastError, toastSuccess } = useToast()
  const [loading, setLoading] = useState(false)

  const handleNormalError = useCallback(
    (error, tx?: TxResponse) => {
      logError(error)

      /* custom */
      let recursiveErr = error

      let reason: string | undefined

      // for MetaMask
      if (recursiveErr?.data?.message) {
        reason = recursiveErr?.data?.message
      } else {
        // for other wallets
        // Reference
        // https://github.com/Uniswap/interface/blob/ac962fb00d457bc2c4f59432d7d6d7741443dfea/src/hooks/useSwapCallback.tsx#L216-L222
        while (recursiveErr) {
          reason = recursiveErr.reason ?? recursiveErr?.data?.message ?? reason
          recursiveErr = recursiveErr.error ?? recursiveErr.data?.originalError
        }
      }

      // const contractAddress = error.transaction.to
      // const messageOnContractMapping = MESSAGE_CODE_ERROR

      const REVERT_STR = 'execution reverted: '
      const indexInfo = reason?.indexOf(REVERT_STR)
      const isRevertedError = indexInfo >= 0

      if (isRevertedError) reason = reason.substring(indexInfo + REVERT_STR.length)
      // if (messageOnContractMapping && messageOnContractMapping[reason]) {
      //   reason = messageOnContractMapping[reason]
      // }

      const stringErrorLocalize = t(reason)
      toastError(
        t('Failed'),
        isRevertedError
          ? t('Transaction failed with error: %reason%', { reason: stringErrorLocalize })
          : `Transaction failed. For detailed error message: ${stringErrorLocalize}`,
      )
      // if (tx) {
      //   toastError(
      //     t('Error'),
      //     <ToastDescriptionWithTx txHash={tx.hash}>
      //       {t('Please try again. Confirm the transaction and make sure you are paying enough gas!')}
      //     </ToastDescriptionWithTx>,
      //   )
      // }
      // if (error?.data?.message) {
      //   toastError(t('Error'), t(error?.data?.message))
      // } else {
      //   toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      // }
    },
    [t, toastError],
  )

  const handleMessageError = useCallback(
    (error) => {
      logError(error)
      let recursiveErr = error

      let reason: string | undefined

      // for MetaMask
      if (recursiveErr?.data?.message) {
        reason = recursiveErr?.data?.message
      } else {
        // for other wallets
        // Reference
        // https://github.com/Uniswap/interface/blob/ac962fb00d457bc2c4f59432d7d6d7741443dfea/src/hooks/useSwapCallback.tsx#L216-L222
        while (recursiveErr) {
          reason = recursiveErr.reason ?? recursiveErr?.data?.message ?? reason
          recursiveErr = recursiveErr.error ?? recursiveErr.data?.originalError
        }
      }

      // const contractAddress = error.transaction.to
      // const messageOnContractMapping = MESSAGE_CODE_ERROR

      const REVERT_STR = 'execution reverted: '
      const indexInfo = reason?.indexOf(REVERT_STR)
      const isRevertedError = indexInfo >= 0

      if (isRevertedError) reason = reason.substring(indexInfo + REVERT_STR.length)
      // if (messageOnContractMapping && messageOnContractMapping[reason]) {
      //   reason = messageOnContractMapping[reason]
      // }

      const stringErrorLocalize = t(reason)

      toastError(
        t('Failed'),
        isRevertedError
          ? t('Transaction failed with error: %reason%', { reason: stringErrorLocalize })
          : `Transaction failed. For detailed error message: ${stringErrorLocalize}`,
      )
    },
    [toastError],
  )

  const fetchWithCatchTxError = useCallback(
    async (callTx: () => Promise<TxResponse>): Promise<TransactionReceipt | null> => {
      let tx: TxResponse = null

      try {
        setLoading(true)

        /**
         * https://github.com/vercel/swr/pull/1450
         *
         * wait for useSWRMutation finished, so we could apply SWR in case manually trigger tx call
         */
        tx = await callTx()

        toastSuccess(`${t('Transaction Submitted')}!`, <ToastDescriptionWithTx txHash={tx.hash} />)

        const receipt = await tx.wait()

        return receipt
      } catch (error: any) {
        if (!isUserRejected(error)) {
          if (isRevertContract(error)) {
            handleMessageError(error)
          } else if (!tx) {
            handleNormalError(error)
          } else {
            provider
              .call(tx, tx.blockNumber)
              .then(() => {
                handleNormalError(error, tx)
              })
              .catch((err: any) => {
                if (isGasEstimationError(err)) {
                  handleNormalError(error, tx)
                } else {
                  handleMessageError(err)
                }
              })
          }
        }
      } finally {
        setLoading(false)
      }

      return null
    },
    [handleNormalError, handleMessageError, provider, toastSuccess, t],
  )

  return {
    fetchWithCatchTxError,
    loading,
  }
}
