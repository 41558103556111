/* eslint no-param-reassign: "error" */
import { getHypeNFTv2Contract } from 'utils/contractHelpers'
import hypeNFTv2Abi from 'config/abi/hypeNFTv2.json'
import { getHypeNFTv2Address } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'
import { getPriceCoin } from 'utils/getPriceCoin'
import { footballApi } from 'api'
import axiosClient from 'api/axiosClient'
import { bscRpcProvider } from 'utils/providers'
import { IBlastPointVault, IListNFTRarity, IMintProps, IReferalPerformance, IStats } from './types'
import { RPC_API } from './constants'

export const getMinPriceMint = async (chainId: number) => {
  try {
    const result = await getHypeNFTv2Contract(null, chainId).mintPrice()
    return Number(result)
  } catch (error) {
    console.error('getMinPriceMint', error)
    return 0
  }
}

export const getInitialMint = async (chainId: number): Promise<IMintProps> => {
  try {
    const calls = [
      {
        address: getHypeNFTv2Address(chainId),
        name: 'mintPrice',
        params: [],
      },
      {
        address: getHypeNFTv2Address(chainId),
        name: 'dropFrom',
        params: [],
      },
    ]
    const [[resMinPrice], [resDropFrom]] = await multicallv2({ abi: hypeNFTv2Abi, calls, chainId })
    return {
      minPrice: Number(resMinPrice),
      startIn: Number(resDropFrom) * 1000,
    }
  } catch (error) {
    console.error('getInitMint', error)
    return {} as IMintProps
  }
}

export const getStats = async (chainId: number): Promise<IStats> => {
  try {
    const calls = [
      {
        address: getHypeNFTv2Address(chainId),
        name: 'totalMinted',
        params: [],
      },
    ]
    const promies = await Promise.all([
      getHolders(),
      // getHolders(chainId),
      multicallv2({ abi: hypeNFTv2Abi, calls, chainId }),
      getBlastPointVault(),
      getHypeVault(chainId),
      getPriceCoin(['ethereum']),
    ])
    const [holders, [totalMinted], blastVault, hypeVault, coinPrices] = promies
    return {
      totalMinted: Number(totalMinted),
      holders,
      blastVault,
      hypeVault,
      ethereumPrice: coinPrices?.ethereum ?? 0,
    }
    // return {
    //   totalMinted: Number(resTotalMinted),
    // }
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getTotalNftAccount = async (chainId: number, wallerAddress: string): Promise<number> => {
  try {
    const balance = await getHypeNFTv2Contract(null, chainId).balanceOf(wallerAddress)
    return Number(balance)
  } catch (error) {
    return 0
  }
}

export const getListNftByAccount = async (chainId: number, walletAddress: string): Promise<IListNFTRarity> => {
  try {
    const balance = await getTotalNftAccount(chainId, walletAddress)
    const listNfts = await getHypeNFTv2Contract(null, chainId).tokensOfOwnerIn(walletAddress, 0, balance + 100)
    const calls = listNfts.map((item) => ({
      address: getHypeNFTv2Address(chainId),
      name: 'rarity',
      params: [item],
    }))
    const result = await multicallv2({ abi: hypeNFTv2Abi, calls, chainId })
    const flatResult: any[] = result.flat(2).map((v) => Number(v))
    const resultTotal: IListNFTRarity = flatResult.reduce((acc, cur, index) => {
      if (!acc[cur]) acc[cur] = 0
      acc[cur] += 1
      return acc
    }, {})
    return resultTotal
  } catch (error) {
    console.error('getListNftByAccount', error)
    return {} as IListNFTRarity
  }
}

export const getUserMintPrice = async (chainId: number, walletAddress: string, quantity: number): Promise<number> => {
  try {
    const price = await getHypeNFTv2Contract(null, chainId).getUserMintPrice(walletAddress, quantity)
    return Number(price) / 1e18
  } catch (error) {
    console.error('getUserMintPrice', error)
    return 0
  }
}

export const getFreeMintUser = async (chainId: number, walletAddress: string): Promise<number> => {
  try {
    const times = await getHypeNFTv2Contract(null, chainId).freeMintAmount(walletAddress)
    return Number(times)
  } catch (error) {
    console.error('getFreeMintUser', error)
    return 0
  }
}

export const getMintLeaderboard = async (page?: number, limit?: number): Promise<any> => {
  try {
    return await footballApi.getMintLeaderboard(page, limit)
  } catch {
    return null
  }
}

// export const getHolders = async (chainId: number): Promise<number> => {
//   try {
//     const contract = getHypeNFTv2Address(chainId)
//     const data: any = await axiosClient.get(
//       `${RPC_API[chainId]}/api/evm/${chainId}/erc721/${contract}/holders?count=true`,
//     )
//     return data.count
//   } catch (error) {
//     console.error(error)
//     return 0
//   }
// }

export const getBlastPointVault = async (): Promise<IBlastPointVault> => {
  try {
    const result: any = await footballApi.getBlastPointVault()
    return result.data
  } catch {
    console.error('getBlastPointVault')
    return {} as IBlastPointVault
  }
}

export const getHolders = async () => {
  try {
    const result: any = await footballApi.getHolder()
    return result?.data ?? 0
  } catch (error) {
    console.log('getHolder', error)
    return 0
  }
}

export const getHypeVault = async (chainId: number): Promise<number> => {
  try {
    const contractAddress = getHypeNFTv2Address(chainId)
    const balance = await bscRpcProvider.getBalance(contractAddress)
    return Number(balance)
  } catch {
    return 0
  }
}

export const getReferralPerformance = async (): Promise<IReferalPerformance> => {
  try {
    const result = await footballApi.getReferralPerformance()
    return result.data
  } catch {
    return {} as IReferalPerformance
  }
}
