import { Grid, Skeleton, Text } from '@pancakeswap/uikit'
import { FlexGap } from 'components/Layout/Flex'
import { TextGradient } from 'components/TextGardient'
import { useGetListNfts, useGetStats } from 'state/nft/hooks'
import { Item, TextNumber } from './styles'

const Stats = () => {
  const { totalMinted, holders, blastVault, hypeVault, isLoading, ethereumPrice } = useGetStats()
  const { isLoading: isLoadingNftUser, nftUsers } = useGetListNfts()
  return (
    <FlexGap flexDirection="column" alignItems="center" px={[2, null, '5%']} flex={1}>
      <TextGradient as="h2" textTransform="uppercase" fontSize={[46, null, 56]}>
        Stats
      </TextGradient>
      <Grid
        gridGap="20px"
        flexWrap="wrap"
        gridTemplateAreas={[
          `"a b"
          "c d"
          "e f"`,
          null,
          `
        "a . . . . . ."
        "a b b b b b b"
        "a b b b b b b"
        "c c d d . . ."
        "c c d d . . ."
        "e f f . . . ."`,
        ]}
      >
        <Item style={{ gridArea: 'a' }} height={200}>
          <Text as="h6" fontSize={[18, null, 24]} fontWeight={700}>
            HYPERVAULT
          </Text>
          {isLoading ? (
            <Skeleton height={30} width={120} />
          ) : (
            <TextNumber value={Number((hypeVault * ethereumPrice) / 1e18)} fontSize={36} prefix="$" decimals={3} />
          )}
        </Item>
        <Item className="primary" style={{ gridArea: 'b' }}>
          <Text as="h6" fontSize={[18, null, 24]} fontWeight={700}>
            TOTAL MINTED
          </Text>
          {isLoading ? (
            <Skeleton height={30} width={120} />
          ) : (
            <TextNumber value={totalMinted} fontSize={36} decimals={0} />
          )}
        </Item>
        <Item className="primary" style={{ gridArea: 'c' }}>
          <Text as="h6" fontSize={[16, null, 18]}>
            BLAST POINT VAULT
          </Text>
          {isLoading ? (
            <Skeleton height={30} width={120} />
          ) : (
            <TextNumber value={Number(blastVault.LIQUIDITY?.available)} fontSize={28} decimals={0} />
          )}
        </Item>
        <Item style={{ gridArea: 'd' }}>
          <Text as="h6" fontSize={[16, null, 18]}>
            HOLDERS
          </Text>
          <TextNumber value={isLoading ? 0 : holders} fontSize={26} decimals={0} />
        </Item>
        <Item style={{ gridArea: 'e' }}>
          <Text as="h6" fontSize={[16, null, 18]}>
            YOUR HYPERBOLT
          </Text>
          {isLoadingNftUser ? (
            <Skeleton height={30} width={120} />
          ) : (
            <TextNumber value={nftUsers[0]} fontSize={26} decimals={0} />
          )}
        </Item>
        <Item style={{ gridArea: 'f' }}>
          <Text as="h6" fontSize={[16, null, 18]}>
            YOUR HYPERGOLD
          </Text>
          {isLoadingNftUser ? (
            <Skeleton height={30} width={120} />
          ) : (
            <TextNumber value={nftUsers[999] ?? 0} fontSize={26} decimals={0} />
          )}
        </Item>
      </Grid>
    </FlexGap>
  )
}

export default Stats
