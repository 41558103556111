import { footballApi } from 'api'
import { useState, useCallback, useMemo, useEffect } from 'react'
import { useSignMessage, useWeb3React } from '@pancakeswap/wagmi'
import { setCookie } from 'utils/cookie'
// import { useTeamSaved } from 'state/teams/hooks'
import { SIGN_DATA, TOKEN_ID, USER_DATA, WALLET_ADDRESS } from 'config'
import { useToast } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { atomWithStorage } from 'jotai/utils'
import { useAtom } from 'jotai'
import useAuth from './useAuth'

const userDataAtom = atomWithStorage(USER_DATA, '')
const tokenIdAtom = atomWithStorage(TOKEN_ID, '')

interface IDataLogin {
  data: {
    refCode: string
    refWallet?: string
  }
}

export const useLoginWallet = () => {
  const [userData, setUserData] = useAtom(userDataAtom)
  const [tokenId, setTokenId] = useAtom(tokenIdAtom)
  const { toastSuccess, toastError } = useToast()
  const { signMessageAsync } = useSignMessage()
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  // const { refresh: refreshTeamSaved } = useTeamSaved()
  const { t } = useTranslation()

  const handleLoginWithWallet = useCallback(
    async (onLoginSuccess: () => void) => {
      try {
        setPending(true)
        const { data: resSignKey } = await footballApi.requestSignMessage(account)

        const signKey = resSignKey.signKey ?? resSignKey
        localStorage.setItem(SIGN_DATA, signKey)

        const signature = await signMessageAsync({ message: signKey })
        setTokenId(signature)
        localStorage.setItem(WALLET_ADDRESS, account)
        const dataLogin: IDataLogin = await footballApi.login(signature, account)
        toastSuccess(t('Login successfully'))
        setUserData(JSON.stringify({ ...dataLogin.data }))
        onLoginSuccess()
      } catch (error: any) {
        const errorDescription = `${error?.message} - ${error?.data?.message}`
        toastError('Failed to register', errorDescription)
      } finally {
        setPending(false)
      }
    },
    [
      account,
      setPending,
      SIGN_DATA,
      setCookie,
      TOKEN_ID,
      WALLET_ADDRESS,
      toastSuccess,
      toastError,
      t,
      setTokenId,
      setUserData,
      signMessageAsync,
      footballApi,
    ],
  )

  const isLogged = useMemo(() => {
    return !!tokenId
  }, [tokenId])

  const handleLogout = useCallback(() => {
    setTokenId(null)
    setUserData(null)
  }, [setUserData, setTokenId])

  const userRefCode = useMemo(() => {
    return userData ? JSON.parse(userData).refCode : null
  }, [userData])

  useEffect(() => {
    const localWallet = localStorage.getItem(WALLET_ADDRESS)
    if (account && localWallet && account !== localWallet) {
      handleLogout()
      localStorage.setItem(WALLET_ADDRESS, account)
    }
  }, [account, handleLogout])

  return {
    userRefCode,
    pending,
    isLogged,
    handleLoginWithWallet,
    handleLogout,
  }
}
