/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
const coinsName = {
  ethereum: 'ethereum',
  bitcoin: 'bitcoin',
}

export type CoinsName = typeof coinsName[keyof typeof coinsName]

export const getPriceCoin = async (
  coinName: CoinsName[],
  cacheTime = 60000,
): Promise<{ [key in CoinsName]: number }> => {
  try {
    const cache = localStorage.getItem('coinPrices')
    let cachedData: { [key in CoinsName]?: number } = {}
    if (cache) {
      cachedData = JSON.parse(cache)
    }

    const currentTime = Date.now()
    const shouldFetchFromAPI =
      Object.keys(cachedData).length === 0 ||
      currentTime - JSON.parse(localStorage.getItem('lastCacheTime')!) > cacheTime

    if (shouldFetchFromAPI) {
      const listCoins = coinName.join(',')
      const result = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${listCoins}&vs_currencies=usd`)
      const res = await result.json()

      const updatedData: { [key in CoinsName]: number } = Object.keys(res).reduce((acc, key) => {
        const coin = res[key]
        const usd = coin.usd
        acc[key as CoinsName] = usd
        return acc
      }, {} as { [key in CoinsName]: number })

      localStorage.setItem('coinPrices', JSON.stringify(updatedData))
      localStorage.setItem('lastCacheTime', JSON.stringify(currentTime))

      return updatedData
    }
    const cachedDataMapped: { [key in CoinsName]: number } = {}
    for (const key in cachedData) {
      if (cachedData[key]) {
        cachedDataMapped[key as CoinsName] = cachedData[key]!
      }
    }
    return cachedDataMapped
  } catch (error) {
    console.error('Error fetching coin prices:', error)
    return {} as { [key in CoinsName]: number }
  }
}
