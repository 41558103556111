import { Box } from '@pancakeswap/uikit'
import Balance from 'components/Balance'
import { FlexGap } from 'components/Layout/Flex'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { Rank } from './types'

export const Item = styled(FlexGap)`
  border-radius: 12px;
  border: solid 2px;
  border-color: ${({ theme }) => theme.colors.tertiary};
  min-height: 140px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  &.primary {
    background-color: ${({ theme }) => theme.colors.primary0f};
    border: 0;
  }

  &.rewards {
    min-width: 50%;
  }
`

export const TextNumber = styled(Balance)`
  text-align: right;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-family: 'Anta Regular', sans-serif !important;
`

const rankVariants = {
  '1': {
    backgroundImage: 'url(/images/nft/leaderboard-rank-1.webp)',
    backgroundPosition: '125% -80%',
  },
  '2': {
    backgroundImage: 'url(/images/nft/leaderboard-rank-2.webp)',
    backgroundPosition: '110% -45%',
  },
  '3': { backgroundImage: 'url(/images/nft/leaderboard-rank-3.webp)', backgroundPosition: '125% -110%' },
}

export const CardRank = styled(FlexGap)<{
  rank?: Rank
}>`
  border: 2px solid ${({ theme }) => theme.colors.tertiary};
  flex-direction: column;
  background-image: url(/images/nft/leaderboard-rank-bg.webp);
  min-height: 100px;
  flex: 1 auto;
  border-radius: 8px;
  background-repeat: no-repeat;
  ${variant({
    prop: 'rank',
    variants: rankVariants,
  })}
`
export const Badge = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary3D};
  color: ${({ theme }) => theme.colors.text};
  top: -50px;
  padding: 20px;
  box-shadow: 0 2px 5px black;
  height: calc(100% + 40px);
  width: 130px;
  line-height: 50px;
  text-align: center;
  &::before {
    content: '';
    width: 80%;
    height: 80%;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
