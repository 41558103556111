import { useMemo } from 'react'
import { Button, Grid, Skeleton, Text } from '@pancakeswap/uikit'
import { useGetListNfts, useGetStats } from 'state/nft/hooks'
import styled from 'styled-components'
import { FlexGap } from 'components/Layout/Flex'
import { TextGradient } from 'components/TextGardient'
import { TOTAL_SUPPLY_MINT } from './config'
import { Item, TextNumber } from './styles'

const Reward = () => {
  const { totalMinted, blastVault, isLoading } = useGetStats()
  const { isLoading: isLoadingNftUser, nftUsers } = useGetListNfts()

  const hypeReward = useMemo(() => {
    return TOTAL_SUPPLY_MINT * (((nftUsers?.[0] ?? 0) + (nftUsers?.[999] ?? 0)) / totalMinted)
  }, [totalMinted, nftUsers])

  return (
    <FlexGap flexDirection="column" alignItems="center" px={[null, null, '10%']} gap="20px" flex={1}>
      <TextGradient as="h2" textTransform="uppercase" fontSize={[46, null, 56]}>
        Rewards
      </TextGradient>
      <Grid gridTemplateColumns="1fr 1fr" gridGap="20px" flexWrap="wrap" width="100%">
        <Button minWidth="50%" disabled>
          COMING SOON
        </Button>
        <Button minWidth="50%" variant="outline" disabled>
          COMING SOON
        </Button>
      </Grid>
      <Grid gridTemplateColumns="1fr 1fr" gridGap="20px" flex={1} width="100%">
        <Item className="primary rewards">
          <TextItem as="h6">HYPE</TextItem>
          {isLoadingNftUser ? <Skeleton height={30} width={120} /> : <TextBalance value={hypeReward} decimals={0} />}
        </Item>
        <Item className="rewards">
          <TextItem as="h6">ETH</TextItem>
          <TextBalance value={0} decimals={0} />
        </Item>
        <Item className="rewards">
          <TextItem as="h6">BLAST POINT</TextItem>
          {isLoading ? (
            <Skeleton height={30} width={120} />
          ) : (
            <TextBalance value={Number(blastVault.LIQUIDITY?.available) ?? 0} decimals={0} />
          )}
        </Item>
        <Item className="primary rewards">
          <TextItem as="h6">HYPE-ETH LP</TextItem>
          <TextBalance value={0} decimals={0} />
        </Item>
      </Grid>
    </FlexGap>
  )
}

const TextItem = styled(Text)`
  font-size: 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 24px;
  }
`

const TextBalance = styled(TextNumber)`
  font-size: 26;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 36px;
  }
`

export default Reward
