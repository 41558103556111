import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { FetchStatus } from 'config/constants/types'
import useSWR from 'swr'
import {
  getInitialMint,
  getListNftByAccount,
  getMinPriceMint,
  getMintLeaderboard,
  getReferralPerformance,
  getStats,
} from './helpers'
import { ILeaderboard, IListNFTRarity, IMintProps } from './types'

export const useGetMinPriceMint = () => {
  const [minPrice, setMinPrice] = useState<number>(0)
  const { chainId, account } = useActiveWeb3React()

  useEffect(() => {
    if (account && chainId) {
      getMinPriceMint(chainId).then((value) => {
        setMinPrice(value)
      })
    }
  }, [account, chainId])

  return minPrice ?? 0
}

export const useGetInitMint = () => {
  const { chainId } = useActiveWeb3React()
  const { data, status } = useSWR(['getInitMint', chainId], () => getInitialMint(chainId), { refreshInterval: 30000 })

  return {
    ...data,
    isLoading: status !== FetchStatus.Fetched,
  }
}

export const useGetStats = () => {
  const { chainId } = useActiveWeb3React()

  const { data, status } = useSWR(['stats', chainId], () => getStats(chainId), {
    refreshInterval: 60000,
  })

  return {
    ...data,
    isLoading: status !== FetchStatus.Fetched,
  }
}

export const useGetListNfts = (): {
  isLoading: boolean
  nftUsers: IListNFTRarity
} => {
  const { account, chainId } = useActiveWeb3React()
  const { data, status } = useSWR(['listsNft', account, chainId], () => getListNftByAccount(chainId, account))
  return {
    nftUsers: data,
    isLoading: status !== FetchStatus.Fetched,
  }
}

export const useGetLeaderboard = (): {
  topRank: ILeaderboard
  rankList: ILeaderboard
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
} => {
  const { chainId } = useActiveWeb3React()
  const [page, setPage] = useState<number>(1)
  const [limit] = useState<number>(10)
  const { data: dataTop, status } = useSWR(['topLeaderboard', chainId], () => getMintLeaderboard(1, 3))
  const { data } = useSWR(['leaderboard', chainId, page], () => getMintLeaderboard(page, limit))
  const dataTopConvert = useMemo<ILeaderboard>(() => {
    if (dataTop) {
      return {
        docs: dataTop.data.docs,
        limit: dataTop.data.limit,
        page: dataTop.data.page,
        totalDocs: dataTop.data.totalDocs,
        totalPages: dataTop.data.totalPages,
      }
    }
    return {} as ILeaderboard
  }, [dataTop])

  const dataRankConvert = useMemo<ILeaderboard>(() => {
    if (data && data.data && data.data.docs) {
      const list: any[] = data.data?.docs
      return {
        docs: list,
        limit: data.data.limit,
        page: data.data.page,
        totalDocs: data.data.totalDocs,
        totalPages: data.data.totalPages,
      }
    }
    return {} as ILeaderboard
  }, [data, page])

  return {
    topRank: dataTopConvert,
    rankList: dataRankConvert,
    isLoading: status === FetchStatus.Fetching,
    page,
    setPage,
  }
}

export const useGetReferralPerformance = () => {
  const { account } = useActiveWeb3React()
  const { data, status, mutate } = useSWR([account, 'referralPerformance'], () => getReferralPerformance(), {
    refreshInterval: 120000,
  })
  return {
    ...data,
    isLoading: status !== FetchStatus.Fetched,
    refresh: mutate,
  }
}
