import { Box, Button, PasteIcon, Skeleton, Slider, Text, useToast } from '@pancakeswap/uikit'
import Countdown, { zeroPad } from 'react-countdown'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import { FlexGap } from 'components/Layout/Flex'
import Divider from 'components/Divider'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGetInitMint, useGetMinPriceMint, useGetReferralPerformance } from 'state/nft/hooks'
import { useHypeNFTv2Contract } from 'hooks/useContract'
import useCatchTxError from 'hooks/useCatchTxError'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { getFreeMintUser, getMintLeaderboard, getUserMintPrice } from 'state/nft/helpers'
import { useLoginWallet } from 'hooks/useLoginWallet'
// import { useCheckSignMessage } from 'hooks/useCheckSignMessage'
import { BASE_URL } from 'config'
import { CopyButton } from 'components/CopyButton'

const renderer = ({ hours, minutes, days }) => {
  // Render a countdown
  return (
    <FlexGap gap="20px" justifyContent="space-between">
      <BoxTime>
        <Box className="time">{zeroPad(days)}</Box>
        <Text fontSize={14} fontWeight={300} textTransform="uppercase">
          DAYS
        </Text>
      </BoxTime>
      <Text fontWeight={600} mt={2} fontSize={[30, null, 54]}>
        :
      </Text>
      <BoxTime>
        <Box className="time">{zeroPad(hours)}</Box>
        <Text fontSize={14} fontWeight={300} textTransform="uppercase">
          hours
        </Text>
      </BoxTime>
      <Text fontWeight={600} mt={2} fontSize={[30, null, 54]}>
        :
      </Text>
      <BoxTime>
        <Box className="time">{zeroPad(minutes)}</Box>
        <Text fontSize={14} fontWeight={300} textTransform="uppercase">
          mins
        </Text>
      </BoxTime>
    </FlexGap>
  )
}

const MintNFT = () => {
  const { account, chainId } = useActiveWeb3React()
  const [amountMint, setAmountMint] = useState<number>(1)
  const [freeMintTimes, setFreeMintTimes] = useState<number>(0)
  const [chooseDisplay] = useState<number[]>([1, 10, 50, 100])
  const { toastError, toastSuccess } = useToast()
  const hypeNFTv2Contract = useHypeNFTv2Contract()
  const { fetchWithCatchTxError, loading: pendingTx } = useCatchTxError()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { handleLoginWithWallet, pending: isLogging, isLogged, userRefCode } = useLoginWallet()
  const {
    invited,
    pending,
    minted,
    refresh: refreshReferral,
    isLoading: isLoadingReferral,
  } = useGetReferralPerformance()
  const { minPrice, isLoading, startIn } = useGetInitMint()

  const priceMint = useMemo(() => {
    const calPrice = (minPrice / 1e18) * amountMint - (freeMintTimes * minPrice) / 1e18
    return (calPrice < 0 ? 0 : calPrice).toFixed(3)
  }, [minPrice, amountMint, freeMintTimes])

  const fetchFreeMintUser = useCallback(() => {
    getFreeMintUser(chainId, account).then((val) => setFreeMintTimes(val))
    getMintLeaderboard()
  }, [chainId, account, getFreeMintUser])

  const handleMintNFT = useCallback(async () => {
    try {
      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(hypeNFTv2Contract, 'mint', [account, amountMint], {
          value: String(Number(priceMint) * 1e18),
        })
      })
      if (receipt?.status) {
        toastSuccess('MINT Completed')
        fetchFreeMintUser()
      }
    } catch (error: any) {
      console.log('error mint', error)
      // toastError('Error', error?.message)
    }
  }, [
    account,
    priceMint,
    amountMint,
    fetchFreeMintUser,
    freeMintTimes,
    hypeNFTv2Contract,
    callWithGasPrice,
    fetchWithCatchTxError,
    toastSuccess,
  ])

  useEffect(() => {
    if (account && chainId) {
      fetchFreeMintUser()
    }
  }, [account, chainId])

  return (
    <>
      <FlexGap flexWrap="wrap" justifyContent="space-around" gap="40px">
        <BorderCard flex={['auto', null, 0.5]}>
          <img src="/images/nft/bolt-hd.gif" width="100%" height="100%" alt="Mint NFT" />
        </BorderCard>
        <BorderCard flexDirection="column" flex={['auto', null, 0.8]} p={30}>
          <Box>
            <Text textTransform="uppercase" as="h4" fontSize={[18, null, 24]}>
              Time Left
            </Text>
            <Countdown date={new Date(1715151600000)} renderer={renderer} zeroPadTime={2} />
          </Box>
          <Divider style={{ marginTop: '20px' }} />
          <Box>
            <Slider
              min={1}
              max={100}
              name="Amount"
              value={amountMint}
              onValueChanged={(val) => setAmountMint(Math.floor(val))}
            />
            <FlexGap flexDirection="column" gap="20px">
              <FlexGap justifyContent="space-between" alignItems="center">
                <Text textTransform="uppercase" as="h4" fontSize={24} fontWeight={600}>
                  Mint{' '}
                  {freeMintTimes > 0 ? <Text as="span" fontSize={14}>{`(Free ${freeMintTimes} times)`}</Text> : null}
                </Text>
                <FlexGap gap="5px">
                  {chooseDisplay.map((numItems) => (
                    <BoxChooseAmount
                      key={numItems}
                      isActive={numItems === amountMint}
                      onClick={() => setAmountMint(numItems)}
                    >
                      {numItems}
                    </BoxChooseAmount>
                  ))}
                </FlexGap>
              </FlexGap>
              <FlexGap justifyContent="space-between" alignItems="center">
                <Text textTransform="uppercase" as="h4" fontSize={24} fontWeight={600}>
                  For
                </Text>
                <FlexGap>
                  {isLoading ? (
                    <Skeleton height={35} width={196} />
                  ) : (
                    <BoxChooseAmount isActive={false} style={{ flex: 1 }} width="195px">
                      {priceMint} ETH
                    </BoxChooseAmount>
                  )}
                </FlexGap>
              </FlexGap>
            </FlexGap>
          </Box>
        </BorderCard>
      </FlexGap>
      <FlexGap justifyContent="center">
        {account ? (
          isLogged ? (
            <Button width={['100%', null, '50%']} onClick={handleMintNFT} disabled={pendingTx}>
              {pendingTx ? 'Minting...' : 'Mint'}
            </Button>
          ) : (
            <Button
              width={['100%', null, '50%']}
              onClick={() => {
                handleLoginWithWallet(() => {
                  refreshReferral()
                })
              }}
              disabled={isLogging}
            >
              {isLogging ? 'Logging...' : 'Login to mint'}
            </Button>
          )
        ) : (
          <ConnectWalletButton width={['100%', null, '50%']} variant="primary" />
        )}
      </FlexGap>
      {account && isLogged && (
        <FlexGap flexDirection="column">
          <Text textTransform="uppercase" as="h6">
            Invite 5 friends to claim 1 FREE hyperBOLT
          </Text>
          <FlexGap gap="40px" flexWrap="wrap">
            <FlexGap flexDirection="column" flex={1}>
              <Text color="textBody3" fontSize={14} fontWeight={600} my={2}>
                Invitation Link
              </Text>
              <Box backgroundColor="secondary" p="10px 20px" position="relative">
                <FlexGap justifyContent="space-between" alignItems="center" gap="10px">
                  <Text
                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    width={[270, null, 'auto']}
                    overflow="hidden"
                  >
                    {BASE_URL}?ref={userRefCode}
                  </Text>
                  <CopyButton text={`${BASE_URL}?ref=${userRefCode}`} tooltipMessage="Copied!" tooltipTop={5} />
                  {/* <PasteIcon fill="none" /> */}
                </FlexGap>
              </Box>
            </FlexGap>
            <FlexGap width="1px" display={['none', null, null]} backgroundColor="tertiary" />
            <FlexGap alignItems="flex-end" gap="40px" flex={1} flexWrap="wrap" justifyContent="center">
              <FlexGap gap="40px" flex="1 auto" justifyContent={['flex-start', null, null]}>
                <FlexGap flexDirection="column">
                  <Text color="textBody3" fontSize={14} fontWeight={600}>
                    Invited
                  </Text>
                  <Text fontSize={24} fontWeight={600}>
                    {invited ?? 0}
                  </Text>
                </FlexGap>
                <FlexGap flexDirection="column">
                  <Text color="textBody3" fontSize={14} fontWeight={600}>
                    Minted
                  </Text>
                  <Text fontSize={24} fontWeight={600}>
                    {minted ?? 0}
                  </Text>
                </FlexGap>
                <FlexGap flexDirection="column">
                  <Text color="textBody3" fontSize={14} fontWeight={600}>
                    Pending
                  </Text>
                  <Text fontSize={24} fontWeight={600}>
                    {pending ?? 0}
                  </Text>
                </FlexGap>
              </FlexGap>
              {/* <Button variant="outline" scale="lg" minWidth={200}>
                CLAIM
              </Button> */}
            </FlexGap>
          </FlexGap>
        </FlexGap>
      )}
    </>
  )
}

const BoxTime = styled(FlexGap)`
  flex-direction: column;
  justity-content: center;
  text-align: center;
  & .time {
    background-color: ${({ theme }) => theme.colors.primary0f};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    font-size: 36px;
    margin: 10px 0;
    min-width: 60px;
    padding: 10px;
    border-radius: 12px;
    font-family: 'Anta Regular', sans-serif;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & .time {
      font-size: 56px;
      padding: 20px;
      min-width: 120px;
    }
  }
`

const BoxChooseAmount = styled(Box)<{ isActive: boolean }>`
  cursor: pointer;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.text99)};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.background : theme.colors.primary)};
  font-family: 'Anta Regular', sans-serif;
  min-width: 45px;
  min-height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 12px;
  font-weight: 600;
  user-select: none;
  border: 0.12rem solid ${({ theme }) => theme.colors.tertiary};
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.secondary)};
  }
`

const BorderCard = styled(FlexGap)`
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.colors.tertiary};
`

export default MintNFT
