import axios from 'axios'
import { REFERRAL } from 'config'
import queryString from 'query-string'
import axiosClient from './axiosClient'

class FootballApi {
  login = (signature: string, walletAddress: string) => {
    return axiosClient.post(
      '/login',
      {},
      {
        headers: {
          authorization: `Bearer ${signature}`,
          address: walletAddress,
        },
      },
    )
  }

  requestSignMessage = (walletAddress: string) => {
    const refBy = JSON.parse(localStorage.getItem(REFERRAL)) ?? null
    return axiosClient.post('/requestSignMessage', { walletAddress, refBy })
  }

  getMintLeaderboard = (page = 1, limit = 10) => {
    const qs = queryString.stringify({ page, limit })
    return axiosClient.get(`/getMintLeaderbaord/${qs}`)
  }

  getBlastPointVault = () => {
    return axiosClient.get(`/getBlastPointVault`)
  }

  getReferralPerformance = () => {
    return axiosClient.get(`/getReferralPerformance`)
  }

  getHolder = () => {
    return axiosClient.get('/getHolder')
  }
}

export default new FootballApi()
